<template>
  <div
    class="advanced-settings-btns d-flex w-100 m-0 justify-content-space-between pt-24 pt-lg-0"
  >
    <BaseButton class="w-100 mr-md-8" @click="acceptAll">{{
      $label('cookies.cookieBar.acceptAllBtn')
    }}</BaseButton>
    <BaseButton variant="primary" class="w-100 ml-md-8" @click="saveSettings">
      {{ $label('cookies.cookieSettings.saveBtn') }}
    </BaseButton>
  </div>
</template>

<script>
export default {
  props: {
    acceptAll: {
      type: Function,
      required: true,
    },
    saveSettings: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@include media-breakpoint-down(md) {
  .advanced-settings-btns {
    gap: 16px;
    flex-wrap: wrap;

    .btn:first-child {
      order: 3;
    }

    .btn:nth-child(2) {
      order: 2;
    }
  }
}
</style>
