<!-- eslint-disable vue/no-v-html -->
<template>
  <BaseModal
    id="cookieBar"
    hide-header
    data-testid="cookie-bar"
    body-class="cookie-bar-modal__body pl-16 pr-16 pb-32 pt-32"
    footer-class="cookie-bar-modal__footer pl-16 pr-16 pb-24 p-md-40 pt-0"
    content-class="bd-n bdrs-0"
    :scrollable="showAdvancedSettings"
    :static-modal="staticModal"
  >
    <template #body>
      <ClientOnly>
        <h3 class="mb-24 mb-md-8">
          {{ $label('cookies.cookieBar.title') }}
        </h3>
        <p class="mb-0" v-html="$label('cookies.cookieBar.content')" />

        <div v-if="showAdvancedSettings" class="mt-24">
          <h3 class="mb-16">{{ $label('cookies.cookieSettings.title') }}</h3>
          <div
            v-for="(category, index) in Object.keys(categories)"
            :key="category"
            class="mb-16"
          >
            <div class="mb-8 d-flex align-items-center justify-content-between">
              <h4 class="d-inline-block mb-0">
                {{ $label(`cookies.cookieSettings.${category}.title`) }}
              </h4>
              <div class="form-check form-switch">
                <input
                  v-model="categories[category]"
                  :disabled="index === 0"
                  class="form-check-input"
                  type="checkbox"
                />
              </div>
            </div>
            <p class="mb-0">
              {{ $label(`cookies.cookieSettings.${category}.content`) }}
            </p>
          </div>
        </div>
      </ClientOnly>
    </template>

    <template v-if="showAdvancedSettings" #footer>
      <AdvancedSettingsFooter
        :accept-all="acceptAll"
        :save-settings="setConsent"
      />
    </template>

    <template v-else #footer>
      <hr class="divider w-100 mb-32 d-md-none" />
      <CookieBarFooter
        :show-advanced-settings="() => (showAdvancedSettings = true)"
        :accept-only-required="acceptOnlyRequired"
        :accept-all="acceptAll"
      />
    </template>
  </BaseModal>
</template>

<script>
import { COOKIE_CONSENT, UTM_COOKIES } from '~/utils/constants'
import { loadIntercom } from '~/utils/loadIntercom'
import AdvancedSettingsFooter from './AdvancedSettingsFooter'
import CookieBarFooter from './CookieBarFooter'
import { useModal } from '~/composables/useModal'

const UTM_SOURCE_COOKIE = 'bc_ft_utm_source'

export default {
  components: {
    AdvancedSettingsFooter,
    CookieBarFooter,
  },
  props: {
    staticModal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const modal = useModal('cookieBar', { backdrop: 'static' })
    const config = useRuntimeConfig()

    return {
      modal,
      config,
    }
  },
  data() {
    return {
      showAdvancedSettings: false,
      categories: {
        requiredCookies: true,
        functionalCookies: true,
        analyticalCookies: true,
        marketingCookies: true,
      },
    }
  },
  watch: {
    $route() {
      const cookieConsent = this.getCookiesConsent()
      this.showAdvancedSettings = this.isPrivacyPage()

      if (!this.isPrivacyPage() && !cookieConsent) {
        this.modal.show()
      }
    },
  },
  mounted() {
    const cookieConsent = this.getCookiesConsent()

    if (!this.isPrivacyPage() && !cookieConsent) {
      this.modal?.show()
    } else if (cookieConsent) {
      this.categories = {
        ...this.categories,
        ...cookieConsent,
      }

      this.$gtmTracking.setConsent(this.categories, 'default')
      this.setUtmCookies()
      this.loadTools()
    }

    this.showAdvancedSettings = this.isPrivacyPage()
  },
  methods: {
    getCookiesConsent() {
      return this.$localStorage.getItem(COOKIE_CONSENT)
    },
    loadTools() {
      this.loadGTM()
      this.loadIntercom()
    },
    storeConsentToStorage() {
      this.$localStorage.addItem(
        COOKIE_CONSENT,
        {
          functionalCookies: this.categories.functionalCookies,
          analyticalCookies: this.categories.analyticalCookies,
          marketingCookies: this.categories.marketingCookies,
        },
        182,
      )
    },
    transformCategoriesToString() {
      let str = ''

      Object.keys(this.categories).map((cat) => {
        str += `${cat}: ${this.categories[cat]}; `
      })

      return str
    },
    pushCookieConsentEvent() {
      this.$gtmTracking.push({
        event: 'cookie_consent',
        eventCategory: 'Cookie consent',
        eventAction: 'click',
        eventLabel: this.transformCategoriesToString(),
      })
    },
    setConsent() {
      const isUpdate = this.getCookiesConsent()
      const consentType = !isUpdate ? 'default' : 'updated'

      this.storeConsentToStorage()

      this.modal.hide('cookieBar')

      this.$gtmTracking.setConsent(this.categories, consentType)
      this.setUtmCookies()
      this.loadTools()

      if (this.categories.analyticalCookies) {
        setTimeout(this.pushCookieConsentEvent, 500)
      }

      if (isUpdate) {
        window.location.reload(true)
      }

      if (!isUpdate && this.categories.analyticalCookies) {
        this.$gtmTracking.pushNuxtRouteEvent()
      }
    },
    loadGTM() {
      if (
        this.categories.analyticalCookies ||
        this.categories.marketingCookies
      ) {
        this.$gtm.enable(true)
      }
    },
    getCookieMaxAge() {
      return 60 * 60 * 24 * 120
    },
    setUtmCookies() {
      if (this.categories.marketingCookies) {
        try {
          const url = new URL(window.location.href)
          url.searchParams.forEach((value, key) => {
            if (UTM_COOKIES.includes(key)) {
              const cookieName = 'bc_ft_' + key

              if (!useCookie(cookieName).value) {
                const cookie = useCookie(cookieName, {
                  maxAge: this.getCookieMaxAge(),
                })
                cookie.value = value
              }
            }
          })

          if (!useCookie(UTM_SOURCE_COOKIE).value) {
            const cookie = useCookie(UTM_SOURCE_COOKIE, {
              maxAge: this.getCookieMaxAge(),
            })
            cookie.value = 'direct'
          }
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }
    },
    loadIntercom() {
      const isProd = this.config.public.ENV === 'prod'
      if (this.categories.functionalCookies && !window.Intercom && isProd) {
        loadIntercom()
      }
    },
    acceptAll() {
      Object.keys(this.categories).map((category) => {
        this.categories[category] = true
      })

      this.setConsent()
    },
    acceptOnlyRequired() {
      Object.keys(this.categories).map((category) => {
        if (category !== 'requiredCookies') {
          this.categories[category] = false
        }
      })

      this.setConsent()
    },
    isPrivacyPage() {
      return this.$route.name === 'privacy'
    },
  },
}
</script>
