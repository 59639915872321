export const loadIntercom = () => {
  window.intercomSettings = {
    app_id: 'wc14h0sf',
  }
  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/wc14h0sf'
  ;(function () {
    var w = window
    var ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      var d = document
      // eslint-disable-next-line no-var
      var i = function () {
        i.c(arguments)
      }
      i.q = []
      i.c = function (args) {
        i.q.push(args)
      }
      w.Intercom = i
      var l = function () {
        var s = d.createElement('script')
        s.type = 'text/javascript'
        s.defer = true
        s.src = 'https://widget.intercom.io/widget/wc14h0sf'
        var x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }

      l()
    }
  })()

  window.Intercom('boot', {
    app_id: 'wc14h0sf',
  })
}
