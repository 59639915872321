<template>
  <div class="cookie-bar-btns d-flex w-100 m-0 justify-content-space-between">
    <BaseButton
      data-testid="required-btn"
      variant="link"
      class="pr-0 pl-0 w-100"
      @click="acceptOnlyRequired"
    >
      {{ $label('cookies.cookieBar.onlyNecessaryBtn') }}
    </BaseButton>
    <BaseButton
      data-testid="advanced-btn"
      class="w-100 ml-md-16"
      @click="showAdvancedSettings"
    >
      {{ $label('cookies.cookieBar.advancedSettingsBtn') }}
    </BaseButton>
    <BaseButton
      data-testid="accept-all-btn"
      variant="primary"
      class="w-100 ml-md-16"
      @click="acceptAll"
    >
      {{ $label('cookies.cookieBar.acceptAllBtn') }}
    </BaseButton>
  </div>
</template>

<script>
export default {
  props: {
    acceptOnlyRequired: {
      type: Function,
      required: true,
    },
    showAdvancedSettings: {
      type: Function,
      required: true,
    },
    acceptAll: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-link {
  text-decoration: underline;
}

@include media-breakpoint-down(md) {
  .cookie-bar-btns {
    gap: 16px;
    flex-wrap: wrap;

    .btn:first-child {
      order: 3;
    }

    .btn:nth-child(2) {
      order: 2;
    }
  }
}
</style>
